















































































































































































































































































@import '~@/styles/import';

.table {
  td {
    vertical-align: top;
  }
}

.costs {
  .table {
    table-layout: fixed;
  }
}

